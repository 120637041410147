import styled from "styled-components";
import Container from "../../components/Container";
import { roadmapData } from "../../config/data";
import { IMAGE_SRC } from "../../config/image";

const RoadmapContainer = styled.div`
  background-color: #f3b577;
  background-image: url(${IMAGE_SRC.background.bgThree});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 30%;
  padding-bottom: 400px;

  @media screen and (max-width: 768px) {
    background-size: contain;
    padding-bottom: 280px;
  }
  @media screen and (max-width: 500px) {
    padding-bottom: 100px;
  }
`;

const RoadmapItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => props.flexDirection};
  img {
    width: 40%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    img {
      width: 100%;
      margin-bottom: 20px;
      padding: 0 5%;
    }
  }
`;

const RoadmapItemTitle = styled.div`
  color: #251230;
  font-size: 45px;
  font-family: "Titan One", cursive;
  margin-bottom: 10px;
  text-shadow: 1px 3px 3px rgb(255 0 0 / 20%);

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

const RoadmapSection = () => {
  return (
    <RoadmapContainer>
      <Container>
        <div className="flex justify-center items-center mt-m-60">
          <img
            id="roadmap"
            src={IMAGE_SRC.title.roadmap}
            alt="Roadmap"
            width="600"
          />
        </div>

        <div>
          {roadmapData.map((data, index) => (
            <RoadmapItem
              flexDirection={index % 2 === 0 ? "row" : "row-reverse"}
              key={index}
            >
              <img src={data.imgSrc} alt="roadmap" className="px-20" />
              <div>
                <RoadmapItemTitle>{data.title}</RoadmapItemTitle>
                {/* <div className="md:text-xl text-base">{data.text}</div> */}
              </div>
            </RoadmapItem>
          ))}
        </div>
      </Container>
    </RoadmapContainer>
  );
};

export default RoadmapSection;
