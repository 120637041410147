import Container from "../../components/Container";
import { utilityData } from "../../config/data";
import { IMAGE_SRC } from "../../config/image";

const UtilitySection = () => {
  return (
    <div style={{ background: "#73efd3" }}>
      <Container>
        <div className="flex justify-center items-center" id="utility">
          <img src={IMAGE_SRC.title.utility} alt="Utiltiy" width="350" />
        </div>
        <div className="md:grid md:grid-cols-3 grid-cols-2 flex flex-col justify-center items-center">
          <div className="col-span-2 md:mr-20 mr-0 text-4xl">
            <div className="mt-8 text-black sm:text-lg text-base px-4">
              {utilityData.map((data, index) => (
                <div className="mt-4 rounded-xl p-4 bg-yellow-100" key={index}>
                  <div className="text-2xl">{data.title}</div>
                  <div
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: data.text,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-1 md:mt-0 mt-5 flex justify-center items-center">
            <img
              src={IMAGE_SRC.utility}
              alt="utility"
              width="520"
              className="md:mb-0 mb-10"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default UtilitySection;
