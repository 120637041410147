import styled from "styled-components";
import { IMAGE_SRC } from "../config/image";
import Container from "./Container";
import SocialIcons from "./SocialIcons";

const FooterSectionContainer = styled.div`
  background-image: url(${IMAGE_SRC.background.bgSix});
  background-size: 100% 100%;
`;

const Footer = () => {
  return (
    <FooterSectionContainer>
      <Container>
        <div className="grid sm:grid-cols-2 grid-cols-1 py-20">
          <div className="flex justify-center items-center flex-col">
            <img src={IMAGE_SRC.logo} alt="logo" width="400" className="mb-2" />
            <SocialIcons />
          </div>
          <div className="grid grid-cols-2 text-white text-xl px-10 sm:mt-0 mt-6">
            <a className="cursor-pointer" href="#home">
              Home
            </a>
            <a className="cursor-pointer" href="#utility">
              Utility
            </a>
            <a className="cursor-pointer" href="#about">
              About
            </a>
            <a className="cursor-pointer" href="#team">
              Dream Team
            </a>
            <a className="cursor-pointer" href="#roadmap">
              Roadmap
            </a>
            <a className="cursor-pointer" href="#faq">
              FAQ
            </a>
          </div>
        </div>
        <div className="text-center text-white text-2xl">
          © Copyright 2022 • Dreamachine • All rights reserved
        </div>
      </Container>
    </FooterSectionContainer>
  );
};

export default Footer;
