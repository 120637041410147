import { IMAGE_SRC } from "./image";

export const roadmapData = [
  {
    imgSrc: IMAGE_SRC.roadmap.distributed,
    title: "Phase 1: Dreamlist spots are distributed",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin gravida congue metus vel. Sollicitudin vel nunc sit in. Nunc facilisi vel purus diam sed mauris. Enim dictum nulla pulvinar non tellus. Netus adipiscing pulvinar congue convallis malesuada egestas vestibulum lacus. Dolor, ultricies donec purus posuere nulla eget vulputate eros, id. Odio adipiscing vel nulla pellentesque.",
  },
  {
    imgSrc: IMAGE_SRC.roadmap.takePlace,
    title: "Phase 2: Dreamlist mint takes place",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin gravida congue metus vel. Sollicitudin vel nunc sit in. Nunc facilisi vel purus diam sed mauris. Enim dictum nulla pulvinar non tellus. Netus adipiscing pulvinar congue convallis malesuada egestas vestibulum lacus. Dolor, ultricies donec purus posuere nulla eget vulputate eros, id. Odio adipiscing vel nulla pellentesque.",
  },
  {
    imgSrc: IMAGE_SRC.roadmap.publicMint,
    title: "Phase 3: Public mint takes place",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin gravida congue metus vel. Sollicitudin vel nunc sit in. Nunc facilisi vel purus diam sed mauris. Enim dictum nulla pulvinar non tellus. Netus adipiscing pulvinar congue convallis malesuada egestas vestibulum lacus. Dolor, ultricies donec purus posuere nulla eget vulputate eros, id. Odio adipiscing vel nulla pellentesque.",
  },
  {
    imgSrc: IMAGE_SRC.roadmap.utility,
    title:
      "Phase 4: Holder's roadmap revealed . . . real utility with real value",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin gravida congue metus vel. Sollicitudin vel nunc sit in. Nunc facilisi vel purus diam sed mauris. Enim dictum nulla pulvinar non tellus. Netus adipiscing pulvinar congue convallis malesuada egestas vestibulum lacus. Dolor, ultricies donec purus posuere nulla eget vulputate eros, id. Odio adipiscing vel nulla pellentesque.",
  },
];

export const teamData = [
  {
    imgSrc: IMAGE_SRC.team.ralienskier,
    name: "Ralienskier",
    position: "Founder / Visionary & <br /> Public Relations.",
    twitterLink: "https://twitter.com/ralienskier",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor commodo netus elementum vestibulum turpis a. ",
  },
  {
    imgSrc: IMAGE_SRC.team.andrew,
    name: "Andrew K.",
    position: "Lead Developer & <br /> Security.",

    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor commodo netus elementum vestibulum turpis a. ",
  },
  {
    imgSrc: IMAGE_SRC.team.drew,
    name: "Drew",
    position: "Lead Artist & <br /> Art Visionary.",
    twitterLink: "https://twitter.com/Mr_PaintDrew",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor commodo netus elementum vestibulum turpis a. ",
  },
];

export const faqData = [
  {
    question: "1. What is special about the Dream Team?",
    answer:
      "We are a group of highly passionate entrepreneurs from all around the world. The NFT space brought us together & we want to do the same for our holders. Each one of us brings something unique to the table & will allow Dreamachine to build something very special.",
  },
  {
    question: "2. How many can I mint?",
    answer: "There will be a max of 5 per wallet.",
  },
  {
    question: "3. When is the mint?",
    answer: "Spring/Summer 2022",
  },
  {
    question: "4. What will the mint price be?",
    answer: "TBA",
  },
  {
    question: "5. Is there a significance to the mint price?",
    answer: "TBA",
  },
  {
    question: "6. What is the community wallet?",
    answer:
      "This is a multi-sig wallet used to provide valuable applications to holders.",
  },
  {
    question: "7.What is the Dream Dao?",
    answer:
      "A DAO is a decentralized autonomous organization with a shared wallet and cap table. <br/> Holding DreamWalker’s entitles the owner a membership seat in the DAO, used to make collaborative decisions on how to spend funds held in the Community Treasury. Every 3 DreamWalkers = 1 Vote on proposals. <br />DAO membership will also offer members access to exclusive chats, events and opportunities.",
  },
];

export const rarityData = [
  {
    icon: IMAGE_SRC.rarity.background,
    bgColor: "#F3B577",
    textColor: "#D57413",
    type: "Backgrounds",
    value: "5",
  },
  {
    icon: IMAGE_SRC.rarity.body,
    bgColor: "#2C63DD",
    textColor: "#210556",
    type: "Bodies",
    value: "16",
  },
  {
    icon: IMAGE_SRC.rarity.headwear,
    bgColor: "#73EFD3",
    textColor: "#0DBEBE",
    type: "Headwear",
    value: "25",
  },
  {
    icon: IMAGE_SRC.rarity.glass,
    bgColor: "#6ECCED",
    textColor: "#2C63DD",
    type: "Glasses",
    value: "6",
  },
  {
    icon: IMAGE_SRC.rarity.eye,
    bgColor: "#E2495F",
    textColor: "#B31027",
    type: "Eyes",
    value: "16",
  },
  {
    icon: IMAGE_SRC.rarity.mouth,
    bgColor: "#DCBDFF",
    textColor: "#8D4BD8",
    type: "Mouth",
    value: "5",
  },
  {
    icon: IMAGE_SRC.rarity.ear,
    bgColor: "#35B577",
    textColor: "#FF0013",
    type: "Earring",
    value: "5",
  },
  {
    icon: IMAGE_SRC.rarity.backbling,
    bgColor: "#9863DD",
    textColor: "#210556",
    type: "Backbling",
    value: "13",
  },
  {
    icon: IMAGE_SRC.rarity.clothing,
    bgColor: "#AEFC07",
    textColor: "#0DBEBE",
    type: "Clothing",
    value: "25",
  },
];

export const utilityData = [
  {
    title: "Community",
    text: "The most VALUABLE utility a project can offer . . . whether it’s business or friendship, we want our holders to make IRL connections in the discord.",
  },
  {
    title: "SoulMates",
    text: "“Behind every successful man, there’s a strong woman” <br/>•Free + gas to all holders",
  },
  {
    title: "Dream School",
    text: "Hosting events that provide IRL value. <br/>•Seminars<br/>•Motivational speakers <br/>•Social networking ",
  },
  {
    title: "Dreamachine Brand",
    text: "Holders-only drops will be 🔥",
  },
  {
    title: "Dream DAO",
    text: "Allocating a percentage of both mint & royalties to a community governed DAO that will control a wallet used exclusively for holders. <br /> •Giveaways <br/>    •Charity <br/>  •Building the brand",
  },
];
