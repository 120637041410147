import styled from "styled-components";
import { IMAGE_SRC } from "../../config/image";

const HeroSectionContainer = styled.div`
  background-image: url(${IMAGE_SRC.background.bgOne});
  min-height: 100vh;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    background-image: url(${IMAGE_SRC.mobileBg.bgOne});
    background-size: cover;
  }
`;

const Logo = styled.img`
  max-width: 600px;

  @media (max-width: 600px) {
    max-width: 450px;
  }

  @media (max-width: 450px) {
    max-width: 320px;
  }
`;

const Description = styled.div`
  font-size: 36px;
  color: #ffffff;
  text-shadow: 0px 4px 0px #2a1333;
  text-align: center;
  -webkit-text-stroke: 2px #2a1333;

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const HeroSection = () => {
  return (
    <HeroSectionContainer>
      <div className="flex flex-col justify-center items-center" id="#home">
        <Logo src={IMAGE_SRC.logo} alt="logo" />
        <Description>Making Dreams Come True Since 22'</Description>
        <div className="flex mt-4 flex-wrap items-center justify-center">
          {/* <Button className="mr-2 mb-4">
            <img
              src={IMAGE_SRC.buttonIcons.openSea}
              alt="opensea"
              className="mr-2 w-8"
            />
            Buy on OpenSea
          </Button>
          <Button className="mb-4">
            <img
              src={IMAGE_SRC.buttonIcons.cloud}
              alt="opensea"
              className="mr-2 w-12"
            />
            Follow your dreams
          </Button> */}
        </div>
      </div>
    </HeroSectionContainer>
  );
};

export default HeroSection;
