import styled from "styled-components";
import Container from "../../components/Container";
import FaqCard from "../../components/FaqCard";
import { faqData } from "../../config/data";
import { IMAGE_SRC } from "../../config/image";

const FaqSectionContainer = styled.div`
  background-color: #2a0c62;
  background-image: url(${IMAGE_SRC.background.bgFive});
  background-size: contain;
  mix-blend-mode: exclusion;
`;

const FaqTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -400px;
  @media screen and (max-width: 527px) {
    margin-top: -250px;
  }
`;

const FaqSection = () => {
  return (
    <FaqSectionContainer>
      <img src={IMAGE_SRC.background.bgFour} alt="blur" />
      <FaqTitle>
        <img id="faq" src={IMAGE_SRC.title.faq} alt="FAQ" width="400" />
      </FaqTitle>
      <Container>
        <div>
          {faqData.map((data, index) => (
            <FaqCard key={index} data={data} />
          ))}
        </div>
      </Container>
    </FaqSectionContainer>
  );
};

export default FaqSection;
