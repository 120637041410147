import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HomePage from "./page/home/index";

const App = () => {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
};

export default App;
