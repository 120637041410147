import { useRef, useState } from "react";
import styled from "styled-components";
import { IMAGE_SRC } from "../config/image";
import SocialIcons from "./SocialIcons";

const NavigationContianer = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
  padding: 20px 5%;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5%;

  background: #ffffff;
  border: 7px solid #251230;
  box-sizing: border-box;
  box-shadow: 0px 10px 0px #2a1333;
  border-radius: 100px;
`;
const SideBar = styled.div`
  position: fixed;
  background: #000000;
  top: 0;
  right: 0;
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  height: 100%;
  transform-origin: right center;
  transform: scaleX(0);
  transition: all 0.5s;
  font-size: 32px;
  z-index: 99;
  width: 100%;
`;
const NavMenu = styled.div`
  color: #251230;
  font-size: 20px;
  @media (max-width: 1080px) {
    display: none;
  }
`;
const NavItem = styled.a`
  margin-left: 20px;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    color: #ef5da8;
  }
`;

const SocialIconWrap = styled.div`
  @media only screen and (max-width: 460px) {
    display: none;
  }
`;

const SideBarSocialIconWrap = styled.div`
  margin-top: 40px;
  @media only screen and (min-width: 460px) {
    display: none;
  }
`;
const HamburgerMenu = styled.div`
  display: none;
  z-index: 100;
  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #000000;
  }

  @media only screen and (max-width: 1080px) {
    display: block;
    cursor: pointer;

    .bar:nth-child(2) {
      opacity: ${(props) => (props.hamburgerStatus ? 0 : 1)};
    }

    .bar:nth-child(1) {
      -webkit-transform: ${(props) =>
        props.hamburgerStatus ? "translateY(8px) rotate(45deg)" : "none"};
      transform: ${(props) =>
        props.hamburgerStatus ? "translateY(8px) rotate(45deg)" : "none"};
    }

    .bar:nth-child(3) {
      -webkit-transform: ${(props) =>
        props.hamburgerStatus ? " translateY(-8px) rotate(-45deg)" : "none"};
      transform: ${(props) =>
        props.hamburgerStatus ? " translateY(-8px) rotate(-45deg)" : "none"};
    }
  }
`;

const NavBar = ({ scrollTo }) => {
  const [hamburgerStatus, setHamburgerShow] = useState(false);

  const sidebarRef = useRef(null);
  const toggleVisible = () => {
    if (hamburgerStatus) {
      setHamburgerShow(false);
      sidebarRef.current.style.transform = "scaleX(0)";
    } else {
      setHamburgerShow(true);
      sidebarRef.current.style.transform = "scaleX(1)";
    }
  };
  return (
    <div>
      <NavigationContianer>
        <Navigation>
          <div className="flex justify-center items-center">
            <img
              src={IMAGE_SRC.dream}
              className="cursor-pointer"
              alt="logo"
              width="70"
            />
            <NavMenu className="flex">
              <NavItem href="#home">Home</NavItem>
              <NavItem href="#about">About</NavItem>
              <NavItem href="#roadmap">Roadmap</NavItem>
              <NavItem href="#utility">Utility</NavItem>
              <NavItem href="#team">Dream Team</NavItem>
              <NavItem href="#faq">FAQ</NavItem>
            </NavMenu>
          </div>
          <SocialIconWrap>
            <SocialIcons />
          </SocialIconWrap>
          <HamburgerMenu
            hamburgerStatus={hamburgerStatus}
            onClick={toggleVisible}
          >
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
          </HamburgerMenu>
        </Navigation>
      </NavigationContianer>
      <SideBar ref={sidebarRef} onClick={() => toggleVisible()}>
        <div className="flex flex-col justify-center items-center">
          <NavItem href="#home">Home</NavItem>
          <NavItem href="#about">About</NavItem>
          <NavItem href="#roadmap">Roadmap</NavItem>
          <NavItem href="#utility">Utility</NavItem>
          <NavItem href="#team">Dream Team</NavItem>
          <NavItem href="#faq">FAQ</NavItem>
        </div>

        <SideBarSocialIconWrap>
          <SocialIcons />
        </SideBarSocialIconWrap>
      </SideBar>
    </div>
  );
};

export default NavBar;
