import styled from "styled-components";
import { IMAGE_SRC } from "../config/image";

const SocialIcon = styled.a`
  margin-left: 15px;
  transform: scale(1);
  transition: all 0.4s;
  &:hover {
    transform: scale(1.1);
  }
`;

const SocialIcons = () => {
  return (
    <div className="flex justify-center items-center">
      <SocialIcon href="">
        <img src={IMAGE_SRC.icons.openSea} alt="opensea" width="40" />
      </SocialIcon>
      <SocialIcon href="https://discord.gg/JkQcggvgRb">
        <img src={IMAGE_SRC.icons.discord} alt="discord" width="50" />
      </SocialIcon>
      <SocialIcon href="https://www.instagram.com/dreamachinenft/">
        <img src={IMAGE_SRC.icons.instagram} alt="instagram" width="50" />
      </SocialIcon>
      <SocialIcon href="https://twitter.com/DreamachineNFT">
        <img src={IMAGE_SRC.icons.twitter} alt="twitter" width="40" />
      </SocialIcon>
    </div>
  );
};

export default SocialIcons;
