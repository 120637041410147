import styled from "styled-components";

const StyledContainer = styled.div`
  padding: ${(props) => (props.noPaddingBottom ? "100px 7% 0" : "100px 7%")};
  margin-top: -1px;
`;

const Container = ({ children, noPaddingBottom = false }) => {
  return (
    <StyledContainer noPaddingBottom={noPaddingBottom}>
      {children}
    </StyledContainer>
  );
};

export default Container;
