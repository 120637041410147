import styled from "styled-components";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import FaqSection from "./faq";
import HeroSection from "./hero";
import IntroduceSection from "./introduction";
import RoadmapSection from "./roadmap";
import TeamSection from "./team";
import UtilitySection from "./utility";

const Gap = styled.div`
  height: 200px;
  background-image: linear-gradient(to bottom, #73efd3, #77e493);
`;

const HomePage = () => {
  return (
    <div>
      <NavBar />
      <HeroSection />
      <IntroduceSection />
      <RoadmapSection />
      <UtilitySection />
      <TeamSection />
      <Gap />
      <FaqSection />
      <Footer />
    </div>
  );
};

export default HomePage;
