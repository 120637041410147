export const IMAGE_SRC = {
  icons: {
    discord: require("../assets/images/icons/discord.png").default,
    instagram: require("../assets/images/icons/instagram.png").default,
    twitter: require("../assets/images/icons/twitter.png").default,
    openSea: require("../assets/images/icons/open-sea.png").default,
  },
  background: {
    bgOne: require("../assets/images/bg-1.png").default,
    bgTwo: require("../assets/images/addon.png").default,
    bgThree: require("../assets/images/bg-3.png").default,
    bgFour: require("../assets/images/bg-4.png").default,
    bgFive: require("../assets/images/bg-5.svg").default,
    bgSix: require("../assets/images/bg-6.png").default,
  },
  mobileBg: {
    bgOne: require("../assets/images/bg-1-mobile.png").default,
  },
  logo: require("../assets/images/logo.png").default,
  title: {
    whatDreamachine: require("../assets/images/title/what_dream_machine.png")
      .default,
    roadmap: require("../assets/images/title/roadmap.png").default,
    team: require("../assets/images/title/team.png").default,
    faq: require("../assets/images/title/faq.png").default,
    utility: require("../assets/images/title/Utility.png").default,
  },
  buttonIcons: {
    openSea: require("../assets/images/button-icons/open-sea.png").default,
    cloud: require("../assets/images/button-icons/cloud.png").default,
  },
  placeholder: require("../assets/images/placeholder-image.png").default,
  rarityImages: {
    one: require("../assets/images/rarity/Fantasy-Dreamwalker.png").default,
    two: require("../assets/images/rarity/Elemental-Standing.png").default,
    three: require("../assets/images/rarity/Nightmare-Stand.png").default,
    four: require("../assets/images/rarity/Exotic-Stand.png").default,
  },
  team: {
    andrew: require("../assets/images/Andrew-Frame.png").default,
    drew: require("../assets/images/Drew-NFT_Frame.png").default,
    ralienskier: require("../assets/images/Ralienskier-NFT_Frame.png").default,
  },
  dream: require("../assets/images/dream.png").default,
  whatIsDreamMachine: require("../assets/images/What is the Dreamachine.png")
    .default,
  roadmap: {
    distributed: require("../assets/images/Dreamlist-Distributed.png").default,
    takePlace: require("../assets/images/Mint Takes place.png").default,
    publicMint: require("../assets/images/public-mint.jpg").default,
    utility: require("../assets/images/Phase 4.png").default,
  },
  rarity: {
    clothing: require("../assets/images/rarity-icons/Clothing-Trait.png")
      .default,
    eye: require("../assets/images/rarity-icons/Eyes-Trait.png").default,
    headwear: require("../assets/images/rarity-icons/Headwear-Trait.png")
      .default,
    mouth: require("../assets/images/rarity-icons/Mouth-Trait.png").default,
    backbling: require("../assets/images/rarity-icons/Backbling-Trait.png")
      .default,
    background: require("../assets/images/rarity-icons/Background-Trait.png")
      .default,
    body: require("../assets/images/rarity-icons/Body-Trait.png").default,
    ear: require("../assets/images/rarity-icons/Two Golden Rings.png").default,
    glass: require("../assets/images/rarity-icons/3D Movie.png").default,
  },
  utility: require("../assets/images/utility.jpg").default,
};
