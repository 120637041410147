import { useRef, useState } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import styled from "styled-components";

const FaqCardContainer = styled.div`
  margin-bottom: 20px;
`;

const Question = styled.div`
  background: #f3b577;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  font-size: 32px;
  line-height: 35px;
  color: #000000;
  transition: all 0s;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    padding: 20px 20px;
    font-size: 24px;
  }

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

const Answer = styled.div`
  background: #ffffff;
  border-radius: 0 0 40px 40px;
  color: #000000;
  max-height: 0;
  overflow: hidden;
  transform-origin: top;
  transition: all 0.1s;
  padding: 0 30px;
  font-size: 24px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    padding: 0 20px;
  }

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

const FaqCard = ({ data }) => {
  const { question, answer } = data;
  const [accordionStatus, setAccordionShow] = useState(false);
  const answerRef = useRef(null);
  const questionRef = useRef(null);

  const onAccordionToggle = () => {
    if (!accordionStatus) {
      answerRef.current.style.maxHeight = answerRef.current.scrollHeight + "px";
      questionRef.current.style.borderRadius = "40px 40px 0px 0px";
    } else {
      answerRef.current.style.maxHeight = null;
      questionRef.current.style.borderRadius = "40px";
    }
    setAccordionShow(!accordionStatus);
  };

  return (
    <FaqCardContainer>
      <Question ref={questionRef} onClick={() => onAccordionToggle()}>
        {question}
        <div className="text-white text-5xl cursor-pointer">
          {accordionStatus ? (
            <MdOutlineArrowDropUp />
          ) : (
            <MdOutlineArrowDropDown />
          )}
        </div>
      </Question>
      <Answer ref={answerRef}>
        <div
          className="my-4"
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        />
      </Answer>
    </FaqCardContainer>
  );
};

export default FaqCard;
