import styled from "styled-components";
import Container from "../../components/Container";
import { teamData } from "../../config/data";
import { IMAGE_SRC } from "../../config/image";

const TeamSectionContainer = styled.div`
  background-color: #73efd3;
`;

const TeamPosition = styled.div`
  background: #67afd8;
  border: 4px solid #ffffff;
  box-shadow: 0px 14px 0px #ffffff;
  border-radius: 30px;
`;
const TeamSection = () => {
  return (
    <TeamSectionContainer>
      <Container noPaddingBottom>
        <div className="flex justify-center items-center" id="team">
          <img src={IMAGE_SRC.title.team} alt="Dream Team" width="600" />
        </div>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-3% mt-16">
          {teamData.map((data, index) => (
            <div key={index}>
              <img src={data.imgSrc} className="px-10" alt="team" />
              <TeamPosition className="mt-4 relative flex justify-center items-center pt-5 pb-2">
                <div className="absolute top-m-5 flex justify-center items-center text-4xl">
                  <a
                    href={data.twitterLink}
                    target="_blank"
                    rel="noreferrer"
                    className="transform transition duration-500 hover:scale-125"
                  >
                    <img
                      src={IMAGE_SRC.icons.twitter}
                      className="mr-2"
                      width="40"
                      alt="twitter"
                    />
                  </a>
                  {data.name}
                </div>
                <div
                  className="text-white text-center text-xl"
                  dangerouslySetInnerHTML={{
                    __html: data.position,
                  }}
                />
              </TeamPosition>
            </div>
          ))}
        </div>
      </Container>
    </TeamSectionContainer>
  );
};

export default TeamSection;
