import styled from "styled-components";
import Slider from "react-slick";

import Container from "../../components/Container";
import { IMAGE_SRC } from "../../config/image";
import { rarityData } from "../../config/data";

const IntroduceContainer = styled.div`
  background-image: url(${IMAGE_SRC.background.bgTwo});
  background-size: 100% 60%;
  background-position: bottom;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    background-size: contain;
  }
`;

const RarityItem = styled.div`
  background-color: ${(props) => props.bgColor};
  border: 3px solid #2a1333;
  box-shadow: 0px 7px 0px #2a1333;
  border-radius: 22px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    color: ${(props) => props.textColor};
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
  }

  img {
    max-width: 15%;
  }
`;

const IntroduceSection = () => {
  const settings = {
    // autoplay: true,
    infinite: true,
    lazyLoad: true,
    dots: false,
    arrows: false,
    slidesToShow: 4,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToScroll: 1,
    cssEase: "cubic-bezier(0.070, 0.820, 0.750, 0.750)",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <IntroduceContainer>
      <Container>
        <div
          className="grid md:grid-cols-4 grid-cols-2 flex justify-center items-center"
          id="about"
        >
          <div className="col-span-3 md:mr-20 mr-0">
            <img
              src={IMAGE_SRC.title.whatDreamachine}
              alt="What is Dreamachine"
              width="600"
            />
            <div className="mt-8 text-white sm:text-lg text-base px-4">
              Dreamachine is a community of 10,000 hand-drawn NFTs that act as a
              tool to turn your dreams into reality! We often forget the
              importance of setting intentions & manifesting life goals. Your
              DreamWalker is a digital representation of you and what you want
              to achieve. We believe in creating a unique environment and
              community, while connecting the physical and digital worlds. We
              will enable all holders to connect with each other, create strong
              business relationships, and elevate individual growth. We will
              provide holders with utility that helps excel everyone to
              accomplish their dreams . . . both big and small.
              <br />
              <br /> Our goal is to innovate and build in Web3 & the Metaverse
              for years to come. Doing good for the world, one day at a time.
            </div>
          </div>
          <div className="md:col-span-1 col-span-2 md:mt-0 mt-5 flex justify-center items-center">
            <img
              src={IMAGE_SRC.whatIsDreamMachine}
              alt="whatIsDreamMachine"
              width="250"
              className="rounded-xl"
            />
          </div>
        </div>

        <div className="mt-20">
          <div className="text-white text-center text-4xl">Rarity</div>
          <div className="text-white text-center my-4 md:text-xl text-base">
            Each NFT is algorithmically generated by combining 240+ unique
            traits with varying rarity across categories.
          </div>
          <div className="text-primary text-xl bg-white rounded-3xl text-center my-6 pt-1">
            Rarity Tiers
          </div>
          <div className="">
            <Slider {...settings}>
              <div>
                <img
                  src={IMAGE_SRC.rarityImages.one}
                  alt="rarity"
                  style={{ borderRadius: "50px" }}
                />
                <div className="text-center text-white text-xl">Fantasy</div>
              </div>
              <div>
                <img
                  src={IMAGE_SRC.rarityImages.two}
                  alt="rarity"
                  style={{ borderRadius: "50px" }}
                />
                <div className="text-center text-white text-xl">Elemental</div>
              </div>
              <div>
                <img
                  src={IMAGE_SRC.rarityImages.three}
                  alt="rarity"
                  style={{ borderRadius: "50px" }}
                />
                <div className="text-center text-white text-xl">Nightmare</div>
              </div>
              <div>
                <img
                  src={IMAGE_SRC.rarityImages.four}
                  alt="rarity"
                  style={{ borderRadius: "50px" }}
                />
                <div className="text-center text-white text-xl">Exotic</div>
              </div>
            </Slider>
          </div>
          <div className="text-primary text-xl bg-white rounded-3xl text-center my-6 pt-1">
            Trait Categories
          </div>
          <div className="grid md:grid-cols-3 xs:grid-cols-2 gap-10 mb-64">
            {rarityData.map((data, index) => (
              <RarityItem
                bgColor={data.bgColor}
                textColor={data.textColor}
                key={index}
              >
                <div>
                  <div>{data.value}</div>
                  {data.type}
                </div>
                <img src={data.icon} alt="icon" />
              </RarityItem>
            ))}
          </div>
        </div>
      </Container>
    </IntroduceContainer>
  );
};

export default IntroduceSection;
